import {
  dashboardIcon,
  walletIcon,
  graphIcon,
  fileIcon,
  savingsIcon,
  teamsIcon,
  loanIcon,
  dashboardActiveIcon,
  walletActiveIcon,
  graphActiveIcon,
  fileActiveIcon,
  savingsActiveIcon,
  teamsActiveIcon,
  loanActiveIcon,
  adminDashboardIcon,
  recordsIcon,
  coursesIcon,
  investmentsIcon,
  wrench,
} from '@/assets';

export const studentNavigation = [
  {
    title: 'Dashboard',
    path: '/portal/dashboard',
    icons: { inactive: dashboardIcon, active: dashboardActiveIcon },
  },

  {
    title: 'Wallet',
    path: '/portal/wallet',
    icons: { active: walletActiveIcon, inactive: walletIcon },
  },

  {
    title: 'Invest',
    path: '/portal/invest',
    icons: { active: graphActiveIcon, inactive: graphIcon },
  },
  {
    title: 'My Investments',
    path: '/portal/my-investments',
    icons: { active: loanActiveIcon, inactive: loanIcon },
  },

  {
    title: 'Shares Market',
    path: '/portal/shares-market',
    icons: { active: fileActiveIcon, inactive: fileIcon },
  },

  {
    title: 'Savings',
    path: '/portal/savings',
    icons: { active: savingsActiveIcon, inactive: savingsIcon },
  },

  {
    title: 'Loans',
    path: '/portal/loans',
    icons: { active: loanActiveIcon, inactive: loanIcon },
  },

  {
    title: 'My Team',
    path: '/portal/my-team',
    icons: { active: teamsActiveIcon, inactive: teamsIcon },
  },
];

export const adminNavigation = [
  {
    title: 'Dashboard',
    path: '/admin/dashboard',
    icon: adminDashboardIcon,
  },
  {
    title: 'Users',
    path: '/admin/users',
    icon: recordsIcon,
  },
  {
    title: 'Transactions',
    path: '/admin/transactions',
    icon: recordsIcon,
  },
  {
    title: 'Academy',
    path: '/admin/academy',
    icon: coursesIcon,
  },
  {
    title: 'Investments',
    path: '/admin/investments',
    icon: investmentsIcon,
  },
  {
    title: 'Mentorship',
    path: '/admin/mentorship',
    icon: recordsIcon,
  },
  {
    title: 'Loans',
    path: '/admin/loans',
    icon: recordsIcon,
  },
  {
    title: 'Cashout',
    path: '/admin/cashout',
    icon: recordsIcon,
  },
  {
    title: 'Crypto',
    path: '/admin/crypto',
    icon: recordsIcon,
  },
  {
    title: 'Config',
    path: '/admin/config',
    icon: wrench,
  },
  {
    title: 'Share',
    path: '/admin/share',
    icon: investmentsIcon,
  },
];

export const cardContent = [
  {
    id: 'promotional-balance',
    title: 'Reserved Capital',
    amount: '$0',
    color: '#1565C0',
  },
  {
    id: 'cashout-balance',
    title: 'Profit Balance',
    amount: '$0',
    color: '#699635',
  },
  {
    id: 'running-investments',
    title: 'Running Investments',
    amount: '$0',
    color: '#5615DE',
  },
  {
    id: 'matured-investments',
    title: 'Unmatured ROI',
    amount: '$0',
    color: '#CB0CCF',
  },
];

export const adminCardContent = [
  {
    id: 'active-users',
    title: 'Active Users',
    color: '#1565C0',
  },
  {
    id: 'mentorship-applications',
    title: 'Mentorship Applications',
    color: '#699635',
  },
  {
    id: 'completed-transactions',
    title: 'Completed Transactions',
    color: '#5615DE',
  },
  {
    id: 'running-investments',
    title: 'Running Investments',
    color: '#CB0CCF',
  },
];

export const lineData = {
  labels: [], //['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  datasets: [
    {
      // label: 'Investments',
      data: [], //[450, 380, 750, 125, 700, 750, 900, 800, 400, 950],
      fill: false,
      backgroundColor: 'rgba(8, 91, 197, 0.6)',
      borderColor: 'rgba(8, 91, 197, 0.6)',
      borderWidth: 2,
    },
  ],
};
