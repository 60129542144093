/* eslint-disable no-restricted-imports */
import { Suspense } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import {
  MainLayout,
  StudentPortalLayout,
  AdminPortalLayout,
} from '@/components/Layout';
import { About } from '@/features/about';
import { CourseDetails } from '@/features/academy';
import { Courses } from '@/features/admin/courses';
import { Transactions } from '@/features/admin/transactions';
import { Users } from '@/features/admin/users';
import { UpdatePassword } from '@/features/auth/routes/UpdatePassword';
import { BuyCrypto } from '@/features/buycrypto';
import { Mentorship } from '@/features/mentorship';
import { lazyImport } from '@/utils/lazyImport';

const { Academy } = lazyImport(() => import('@/features/academy'), 'Academy');
const { Dashboard } = lazyImport(
  () => import('@/features/dashboard'),
  'Dashboard',
);
const { Wallet } = lazyImport(() => import('@/features/wallet'), 'Wallet');

const { Investments } = lazyImport(
  () => import('@/features/investments'),
  'Investments',
);

const { MarketShare } = lazyImport(
  () => import('@/features/marketShare'),
  'MarketShare',
);

const { Portfolio } = lazyImport(
  () => import('@/features/portfolio'),
  'Portfolio',
);

const { Savings } = lazyImport(() => import('@/features/savings'), 'Savings');
const { MyInvestments } = lazyImport(
  () => import('@/features/myInvestments'),
  'MyInvestments',
);

const { Loans } = lazyImport(() => import('@/features/loans'), 'Loans');

const { MyTeam } = lazyImport(() => import('@/features/myTeam'), 'MyTeam');

const { Dashboard: AdminDashboard } = lazyImport(
  () => import('@/features/admin/dashboard'),
  'Dashboard',
);

const { Investments: AdminInvestments } = lazyImport(
  () => import('@/features/admin/investments'),
  'Investments',
);

const { Mentorships: AdminMentorship } = lazyImport(
  () => import('@/features/admin/mentorship'),
  'Mentorships',
);

const { Loans: AdminLoans } = lazyImport(
  () => import('@/features/admin/loans'),
  'Loans',
);

const { Cashout } = lazyImport(
  () => import('@/features/admin/transactions'),
  'Cashout',
);

const { Cryptos } = lazyImport(
  () => import('@/features/admin/crypto'),
  'Cryptos',
);

const { Config } = lazyImport(
  () => import('@/features/admin/config'),
  'Config',
);
const { Share } = lazyImport(() => import('@/features/admin/share'), 'Share');

const App = () => {
  const location = useLocation();
  const page: string = location.pathname.split('/app/')[1];

  return (
    <MainLayout page={page}>
      <Suspense
        fallback={
          <div className='h-full w-full flex items-center justify-center'>
            <Spinner size='md' />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const StudentPortal = () => {
  return (
    <StudentPortalLayout>
      <Suspense
        fallback={
          <div className='h-full w-full flex items-center justify-center'>
            <Spinner size='md' />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </StudentPortalLayout>
  );
};

const AdminPortal = () => {
  return (
    <AdminPortalLayout>
      <Suspense
        fallback={
          <div className='h-full w-full flex items-center justify-center'>
            <Spinner size='md' />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </AdminPortalLayout>
  );
};

export const protectedRoutes = [
  { path: '/auth/change-password', element: <UpdatePassword /> },
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'academy', element: <Academy /> },
      { path: 'academy/courses', element: <CourseDetails /> },
      { path: 'about', element: <About /> },
      { path: 'mentorship', element: <Mentorship /> },
      { path: 'buycrypto', element: <BuyCrypto /> },
      { path: '*', element: <Navigate to='.' /> },
    ],
  },
  {
    path: '/portal',
    element: <StudentPortal />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'wallet', element: <Wallet /> },
      { path: 'invest', element: <Investments /> },
      { path: 'my-investments', element: <MyInvestments /> },
      { path: 'shares-market', element: <MarketShare /> },
      { path: 'portfolio', element: <Portfolio /> },
      { path: 'savings', element: <Savings /> },
      { path: 'loans', element: <Loans /> },
      { path: 'my-team', element: <MyTeam /> },
    ],
  },
  {
    path: '/admin',
    element: <AdminPortal />,
    children: [
      {
        path: 'dashboard',
        element: <AdminDashboard />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'transactions',
        element: <Transactions />,
      },
      {
        path: 'academy',
        element: <Courses />,
      },
      {
        path: 'investments',
        element: <AdminInvestments />,
      },
      {
        path: 'mentorship',
        element: <AdminMentorship />,
      },
      {
        path: 'loans',
        element: <AdminLoans />,
      },
      {
        path: 'cashout',
        element: <Cashout />,
      },
      {
        path: 'crypto',
        element: <Cryptos />,
      },
      {
        path: 'config',
        element: <Config />,
      },
      {
        path: 'share',
        element: <Share />,
      },
    ],
  },
];
